import React from "react";
import ReactDOM from "react-dom/client";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
//import "./index.css";
import "./argon-design-system-react.css";
import Accueil from "./Views/Accueil";
import reportWebVitals from "./reportWebVitals";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import Legal from "./Views/Legal";
import ProtectionDonnees from "./Views/ProtectionDonnees";
// import PlanSite from "./Views/PlanSite";
import HospitalisationComplete from "./Views/HospitalisationComplete";
// import ContactHospitalisation from "./Views/ContactHospitalisation";
import ContactAutres from "./Views/ContactAutres";
import Galerie from "./Views/Galerie";
import Equipe from "./Views/Equipe";
import Activites from "./Views/Activites";
import HospitalisationPartielle from "./Views/HospitalisationPartielle";
import HospitalisationNuit from "./Views/HospitalisationNuit";
import Pathologies from "./Views/Pathologies";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#eb6e41",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: "#0066ff",
      main: "#82d232",
      // dark: will be calculated from palette.secondary.main,
      //contrastText: "#ffcc00",
    },
    tertiary: {
      main: "#5296A5",
    },
    white: {
      main: "#ffffff",
    },
    black: { main: "#000000" },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  components: {
    MuiLink: {
      defaultProps: {
        color: "secondary.contrastText",
        underline: "none",
      },
    },
  },
  typography: {
    bigre: {
      color: "black",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/presentation/equipe" render={() => <Equipe />} />
          <Route path="/presentation/galerie" render={() => <Galerie />} />
          <Route
            path="/hospitalisation/complete"
            render={() => <HospitalisationComplete />}
          />
          <Route
            path="/hospitalisation/jour"
            render={() => <HospitalisationPartielle />}
          />
          <Route
            path="/hospitalisation/nuit"
            render={() => <HospitalisationNuit />}
          />
          <Route path="/services/activites" render={() => <Activites />} />
          <Route path="/services/pathologies" render={() => <Pathologies />} />
          <Route path="/mentions-legales" render={() => <Legal />} />
          <Route
            path="/protection-des-donnees"
            render={() => <ProtectionDonnees />}
          />
          {/* <Route
            path="/contact/hospitalisation"
            render={() => <ContactHospitalisation />}
          /> */}
          <Route path="/contact" render={() => <ContactAutres />} />
          {/* <Route path="/plan-du-site" render={() => <PlanSite />} /> */}
          <Route path="/" render={() => <Accueil />} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
