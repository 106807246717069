import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useState } from "react";
import {
  Button,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import logo from "../Assets/olivier OK.png";
import MenuIcon from "@mui/icons-material/Menu";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const pages = [
  {
    nom: "Présentation",
    sous_menus: [
      { nom: "L'équipe", lien: "/#/presentation/equipe" },
      {
        nom: "Visitez la clinique",
        lien: "/#/presentation/galerie",
      },
    ],
    couleur: "primary",
    variant: "text",
  },
  {
    nom: "Hospitalisation",
    sous_menus: [
      {
        nom: "Complete",
        lien: "/#/hospitalisation/complete",
      },
      {
        nom: "Jour",
        lien: "/#/hospitalisation/jour",
      },
      {
        nom: "Nuit",
        lien: "/#/hospitalisation/nuit",
      },
    ],
    couleur: "primary",
    variant: "text",
  },
  {
    nom: "Services",
    sous_menus: [
      {
        nom: "Activités",
        lien: "/#/services/activites",
      },
      {
        nom: "Traitements",
        lien: "/#/services/pathologies",
      },
    ],
    couleur: "primary",
    variant: "text",
  },
  {
    nom: "Contact",
    // sous_menus: [
    //   // {
    //   //   nom: "Rendez-vous par Doctolib",
    //   //   lien: "https://www.doctolib.fr/psychiatre/marseille/yigal-abrami",
    //   //   target: "_blank",
    //   // },
    //   // {
    //   //   nom: "Demande d'hospitalisation",
    //   //   lien: "/#/contact/hospitalisation",
    //   // },
    //   { nom: "Autres", lien: "/#/contact/autres" },
    // ],
    lien: "/#/contact",
    couleur: "secondary",
    variant: "contained",
  },
];

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setOpen(false);
    setAnchorElNav(null);
  };

  const handleMenu = (event, page_nom) => {
    setAnchorEl(page_nom);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ mb: 2 }}>
      <AppBar color="white">
        <Toolbar>
          <Link sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} href="/#">
            <img src={logo} className="App-logo" alt="logo" />
          </Link>
          <Link sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} href="/#">
            <img src={logo} className="App-logo" alt="logo" />
          </Link>

          {/* Le menu apparaissant en petit ecran */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} />
          <IconButton
            size="large"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon color="primary" />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page.nom}>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton
                    href={page.lien ? page.lien : ""}
                    onClick={page.lien ? page.lien : handleClick}
                  >
                    <ListItemText primary={page.nom} />
                    {page.lien ? <></> : open ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  {page.lien ? (
                    <></>
                  ) : (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {page.sous_menus.map((sous_menu) => (
                          <ListItemButton
                            sx={{ pl: 4 }}
                            href={sous_menu.lien}
                            target={sous_menu.target ? sous_menu.target : ""}
                          >
                            <ListItemText>{sous_menu.nom}</ListItemText>
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </List>
              </MenuItem>
            ))}
          </Menu>

          {/* Le menu apparaissant en plein ecran */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
            justifyContent="right"
          >
            {pages.map((page) => (
              <>
                <Button
                  key={page.nom}
                  onClick={(e) => {
                    page.sous_menus && handleMenu(e, page.nom);
                  }}
                  sx={{ my: 2, color: "white", display: "block" }}
                  color={page.couleur}
                  variant={page.variant}
                  href={page.lien ? page.lien : ""}
                >
                  {page.nom}
                  {page.lien ? <></> : <ExpandMore />}
                </Button>
              </>
            ))}
            <Menu
              id="big-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {pages
                  .find((element) => {
                    return element.nom === anchorEl;
                  })
                  ?.sous_menus.map((sous_menu) => (
                    <>
                      <ListItemButton
                        key={sous_menu.nom}
                        href={sous_menu.lien}
                        target={sous_menu.target ? sous_menu.target : ""}
                      >
                        <ListItemText primary={sous_menu.nom} />
                      </ListItemButton>
                    </>
                  ))}
              </List>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}
export default Header;
