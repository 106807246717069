import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Button, Grid } from "@mui/material";
import presentation from "../Assets/Galerie/DEVANTURE/IMG_2869.jpg";
import hospitalisation from "../Assets/Galerie/RTMS/IMG_2970.jpg";
import art from "../Assets/Galerie/SPORT/IMG_3031.jpg";
// import video1 from "../Assets/Galerie/Video/video_presentation.m4v";

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

function Accueil() {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          "flex-direction": "column",
          "min-height": "100vh",
        }}
      >
        <Header />
        <div className="position-relative">
          <section className="section section-lg section-shaped pt-100 ">
            <div className="py-lg-md d-flex">
              <div className="col px-0">
                <div>
                  <div lg="6">
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item justifyContent="center" xs={8}>
                        <h1 className="display-3">
                          Bienvenue à la Clinique Saint-Roch Montfleuri
                        </h1>
                        <p className="lead">
                          La Clinique Montfleuri Saint Roch est un établissement
                          de santé basé à Marseille spécialisé dans la prise en
                          charge des patients adultes présentant un trouble
                          thymique et/ou anxieux.
                        </p>
                      </Grid>
                      <Grid
                        container
                        item
                        justifyContent="center"
                        direction="row"
                        spacing={3}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        <Grid item xs={3}>
                          <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                              component="img"
                              height="300"
                              image={presentation}
                              alt="presentation"
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                Présentation
                              </Typography>
                              <Typography variant="body2">
                                Nichée au creux d'un grand parc fleuri et
                                arboré, la Clinique Saint-Roch Montfleuri vous
                                accueille dans ses locaux
                              </Typography>
                            </CardContent>
                            <div className="btn-wrapper">
                              <Button
                                className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                                color="secondary"
                                href="/#/presentation/equipe"
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fa fa-code" />
                                </span>
                                <span className="btn-inner--text">Equipe</span>
                              </Button>
                              <Button
                                className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                                color="primary"
                                href="/#/presentation/galerie"
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="ni ni-cloud-download-95" />
                                </span>
                                <span className="btn-inner--text">Galerie</span>
                              </Button>
                            </div>
                          </Card>
                        </Grid>
                        <Grid item xs={3}>
                          <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                              component="img"
                              height="300"
                              image={hospitalisation}
                              alt="hospitalisation"
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                Hospitalisation
                              </Typography>
                              <Typography variant="body2">
                                La Clinique Montfleuri Saint Roch vous propose
                                trois modalités d'hospitalisation :
                              </Typography>
                            </CardContent>
                            <div className="btn-wrapper">
                              <Button
                                className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                                color="secondary"
                                href="/#/hospitalisation/complete"
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fa fa-code" />
                                </span>
                                <span className="btn-inner--text">
                                  Complete
                                </span>
                              </Button>
                              <Button
                                className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                                color="primary"
                                href="/#/hospitalisation/jour"
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="ni ni-cloud-download-95" />
                                </span>
                                <span className="btn-inner--text">
                                  Partielle
                                </span>
                              </Button>
                              <Button
                                className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                                color="tertiary"
                                href="/#/hospitalisation/nuit"
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="ni ni-cloud-download-95" />
                                </span>
                                <span className="btn-inner--text">Nuit</span>
                              </Button>
                            </div>
                          </Card>
                        </Grid>
                        <Grid item xs={3}>
                          <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                              component="img"
                              height="300"
                              image={art}
                              alt="services"
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                Services
                              </Typography>
                              <Typography variant="body2">
                                Un programme thérapeutique adapté à chacun, et
                                des prestations hôtellieres pour un séjour
                                optimal
                              </Typography>
                            </CardContent>
                            <div className="btn-wrapper">
                              <Button
                                className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                                color="secondary"
                                href="/#/services/activites"
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fa fa-code" />
                                </span>
                                <span className="btn-inner--text">
                                  Activités
                                </span>
                              </Button>
                              <Button
                                className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                                color="primary"
                                href="/#/services/hotellerie"
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="ni ni-cloud-download-95" />
                                </span>
                                <span className="btn-inner--text">
                                  Hotellerie
                                </span>
                              </Button>
                            </div>
                          </Card>
                        </Grid>
                      </Grid>
                      {/* <Grid item justifyContent="center" xs={8}>
                        <video
                          src={video1}
                          width="100%"
                          height="auto"
                          controls="controls"
                          autoplay="true"
                        />
                      </Grid> */}
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Accueil;
