import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import * as React from "react";
import { Box } from "@mui/system";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Equipe() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          "flex-direction": "column",
          "min-height": "100vh",
        }}
      >
        <Header />
        <div className="position-relative">
          <section className="section section-lg section-shaped pt-100 ">
            <div className="py-lg-md d-flex">
              <div className="col px-0">
                <div>
                  <div lg="6">
                    <h1 className="display-1">L'Equipe</h1>
                    <div className="display-3">L'Equipe Médicale</div>

                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          centered
                        >
                          <Tab label="Médecins psychiatres" {...a11yProps(0)} />
                          <Tab
                            label="Médecins généralistes"
                            {...a11yProps(1)}
                          />
                          <Tab
                            label="Médecins spécialistes"
                            {...a11yProps(2)}
                          />
                        </Tabs>
                      </Box>
                      <TabPanel value={value} index={0}>
                        <p className="lead">
                          <b>Médecins psychiatres</b>
                        </p>
                        <Box width="50%" m="auto">
                          <p className="lead">
                            La permanence médicale est assurée 24h sur 24.
                            Treize médecins psychiatres libéraux assurent le
                            suivi quotidien de leurs patients. Vous verrez votre
                            médecin dans votre service, il est à votre
                            disposition et à celle de votre famille si vous le
                            souhaitez.
                          </p>
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <Box width="50%" m="auto">
                          <p className="lead">
                            <b>Médecins généralistes</b>
                          </p>

                          <p className="lead">
                            Deux médecins généralistes assurent le suivi
                            somatique des patients. Une visite d'admission est
                            réalisée, puis, si besoin, une visite est programmée
                            par les soignants du service.
                          </p>
                        </Box>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <Box width="50%" m="auto">
                          <p className="lead">
                            <b>Médecins spécialistes</b>
                          </p>

                          <p className="lead">
                            Des spécialistes extérieurs peuvent également
                            intervenir sur demande du médecin traitant et avec
                            votre accord.
                          </p>
                        </Box>
                      </TabPanel>
                    </Box>
                    <Grid container justifyContent="center" spacing={3}>
                      <Grid item justifyContent="center" xs={8}>
                        <Divider variant="inset" />
                        <div className="display-3">L'Equipe soignante</div>
                        <p className="lead">
                          Elle est composée d'Infirmiers et d'Aides Soignants
                          présents 24h sur 24 au sein de l'établissement, ils
                          sont à votre écoute pour vous accompagner au mieux
                          dans la prise en charge de votre maladie. Ces équipes
                          sont coordonnées par trois surveillants. Le personnel
                          soignant est réparti en quatre équipes, deux de jour
                          et deux de nuit.
                        </p>
                      </Grid>
                      <Grid item justifyContent="center" xs={8}>
                        <Divider variant="inset" />
                        <div className="display-3">L'Equipe Paramédicale</div>
                        <p className="lead">
                          <Grid container direction="row" spacing={2}>
                            <Grid item xs={6}>
                              <List
                                sx={{
                                  width: "100%",
                                  maxWidth: 360,
                                  bgcolor: "background.paper",
                                }}
                              >
                                <ListItem alignItems="flex-start">
                                  <ListItemText
                                    primary="Psychologues"
                                    secondary="qui peuvent
                                        vous recevoir pour des entretiens individuels sur
                                        prescription médicale ou à des activités
                                        thérapeutiques de groupe"
                                  />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem alignItems="flex-start">
                                  <ListItemText
                                    primary="Art-Thérapeute"
                                    secondary="qui vous
                                        accueillera en activités de groupe afin de vous
                                        permettre de vous exprimer par le biais des arts
                                        plastiques et autres médias"
                                  />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem alignItems="flex-start">
                                  <ListItemText
                                    primary="Neuropsychologue"
                                    secondary="dont le bilan permettra d'évaluer les fonctions 
                                    cognitives et mettre en évidence vos capacités"
                                  />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem alignItems="flex-start">
                                  <ListItemText
                                    primary="Educateur sportif"
                                    secondary="pour vous permettre d'exercer votre corps"
                                  />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                              </List>
                            </Grid>
                            <Grid item xs={6}>
                              <List
                                sx={{
                                  width: "100%",
                                  maxWidth: 360,
                                  bgcolor: "background.paper",
                                }}
                              >
                                <ListItem alignItems="flex-start">
                                  <ListItemText
                                    primary="Kinésithérapeutes"
                                    secondary="qui réaliseront sur prescription médicale les soins de
                                        rééducation organique et les soins antalgiques"
                                  />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem alignItems="flex-start">
                                  <ListItemText
                                    primary="Assistante sociale"
                                    secondary="qui vous aidera dans vos démarches
                                        administratives"
                                  />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem alignItems="flex-start">
                                  <ListItemText
                                    primary="Psychomotricien"
                                    secondary="qui vous aidera, par des exercices appropriés, 
                                    à prendre conscience de votre corps afin de mieux le maîtriser"
                                  />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                              </List>
                            </Grid>
                          </Grid>
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Equipe;
