//import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Box, Typography } from "@mui/material";
import Investissements from "../Assets/L-etiquette-Finance-par.png";

function Legal() {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          "flex-direction": "column",
          "min-height": "100vh",
        }}
      >
        <Header />
        <section className="section section-lg section-shaped pt-100 ">
          <div className="py-lg-md d-flex">
            <div className="col px-0">
              <div>
                <div lg="6">
                  <Box width="75%" m="auto" pb="50px">
                    <Box className="display-2" pb="50px">
                      INFORMATIONS LEGALES ET CONDITIONS GENERALES D'UTILISATION
                      <Box className="display-3">1. IDENTITE</Box>
                      <Box pb="20px">
                        <Typography variant="body1">
                          En utilisant ce site, vous reconnaissez avoir pris
                          connaissance des conditions générales détaillées
                          ci-après, et vous vous engagez à les respecter
                        </Typography>
                        <Typography variant="body1">
                          Conformément aux dispositions de la loi n°2004-575 du
                          21 juin 2004 pour la confiance dans l’économie
                          numérique, nous vous informons que le présent site est
                          la propriété de la clinique Saint-Roch Montfleuri
                          (Société Anonyme Saint Roch), société anonyme au
                          capital de 300 000 € immatriculée au greffe de
                          Marseille sous le numéro RCS Marseille B 054 805 577
                          depuis le 25/10/1954, de numéro de TVA FR94054805577,
                          et dont le siège social est situé au 160 route des
                          camoins 13011 Marseille. L'activité de la société est
                          soumise à l'autorisation de l'HAS, 5 avenue du stade
                          de France, 93210 Saint-Denis
                        </Typography>
                        <Typography variant="body1">
                          Ce site est hébergé par Scaleway, 8 rue de la ville
                          l'évèque 75008 Paris, 08 99 19 37 88
                        </Typography>
                      </Box>
                      <Box pb="20px">
                        <div className="display-3">2. RESPONSABILITE</div>
                        <Typography variant="body1">
                          La clinique Saint Roch Montfleuri met à disposition ce
                          site internet pour la diffusion d'informations à
                          caractère indicatives, non-exhaustive et
                          non-contractuelles concernant le parcours de soin de
                          la clinique.
                        </Typography>
                        <Typography>
                          Elle s'efforce de maintenir l'exactitude des
                          informations présentes sur le site, et se résèrve le
                          droit d'en modifier à tout moment le contenu sans
                          préavis.
                        </Typography>
                        <Typography variant="body1">
                          La clinique décline cependant toute responsabilité en
                          cas d'erreur, inexactitude ou omission dans ces
                          informations. La clinique n’est en effet soumise qu’à
                          une obligation de moyen et ne saurait être tenue pour
                          responsable de tout préjudice direct ou indirect du
                          fait d'une information mal utilisée et/ou qui se
                          serait révélée inexacte ou incomplète, y compris
                          l'inaccessibilité et/ou la présence de virus sur le
                          site, sans que cette liste soit exhaustive.
                        </Typography>
                        <Typography variant="body1">
                          Le site peut inclure des liens vers d'autres sites Web
                          ou d'autres sources Internet. Dans la mesure où la
                          clinique ne peut contrôler ces sites et ces sources
                          externes, celle-ci ne peut être tenue pour responsable
                          de la mise à disposition de ces sites et sources
                          externes, et ne peut supporter aucune responsabilité
                          quant au contenu, publicités, produits, services ou
                          tout autre matériel disponible sur ou à partir de ces
                          sites ou sources externes. De plus, la clinique ne
                          pourra être tenue responsable de tous dommages ou
                          pertes avérés ou allégués consécutifs ou en relation
                          avec l'utilisation ou avec le fait d'avoir fait
                          confiance au contenu, à des biens ou des services
                          disponibles sur ces sites ou sources externes.
                        </Typography>
                      </Box>
                      <Box pb="20px">
                        <div className="display-3">
                          3. PROPRIETE INTELLECTUELLE
                        </div>
                        <Typography variant="body1">
                          Le site peut contenir des informations protégées par
                          le droit de propriété intellectuelle en vigueur ou
                          toute autre loi. Ainsi, sauf mention contraire, les
                          droits de propriété intellectuelle sur les documents,
                          logo, photos, informations de toute nature ou autre,
                          contenus dans le site et chacun des éléments créés
                          pour ce site sont la propriété exclusive de la
                          clinique Saint Roch Montfleuri. Il est interdit de
                          copier ou télécharger tout ou partie de son contenu
                          sauf autorisation écrite expresse et préalable de
                          notre part et en mentionnant la source, et est de
                          nature à être sanctionné conformément aux articles
                          M.335-2 et suivants du Code de la Propriété
                          Intellectuelle.
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="display-2">INDEX D'EGALITE H/F</Box>

                    <Typography variant="body1">
                      En accord avec la législation, la clinique a calculé son{" "}
                      <b>index égalité H/F</b>, dont le détail du calcul peut
                      être retrouvé{" "}
                      <a href="https://egapro.travail.gouv.fr/index-egapro/recherche?query=SOCIETE+ANONYME+SAINT+ROCH&regionCode=93">
                        sur le site du gouvernement.
                      </a>
                    </Typography>
                  </Box>
                  <Box className="display-2">INVESTISSEMENTS DU QUOTIDIEN</Box>

                  <Typography variant="body1">
                    Des Investissements du quotidien ont été financés par l'UE
                    <div>
                      <img
                        src={Investissements}
                        alt="Investissements France Relance"
                        height={250}
                      />
                    </div>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default Legal;
