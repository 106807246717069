import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";

import * as React from "react";

import Carousel from "react-material-ui-carousel";

function importAll(r) {
  let images = {};

  console.log(r);
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
    return images;
  });
  return images;
}

function Item({ name, description, image }) {
  return (
    <Card sx={{ maxWidth: "auto" }}>
      <CardMedia component="img" height="400" image={image} alt={name} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}

const list = importAll(
  require.context("../Assets/Galerie", true, /\.(png|jpe?g|svg)$/)
);
function Galerie() {
  var items = [
    {
      name: "DEVANTURE",
      description: "",
    },
    {
      name: "EXTERIEUR",
      description: "",
    },
    {
      name: "KARAOKE",
      description: "",
    },
    {
      name: "MASSAGE",
      description: "",
    },
    {
      name: "PEINTURE",
      description: "",
    },
    {
      name: "REALITE VIRTUELLE",
      description: "",
    },
    {
      name: "RTMS",
      description: "",
    },
    {
      name: "SENSIBILISATION",
      description: "",
    },
    {
      name: "SIGNALETIQUE",
      description: "",
    },
    {
      name: "SPORT",
      description: "",
    },
  ];
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          "flex-direction": "column",
          "min-height": "100vh",
        }}
      >
        <Header />
        <div className="position-relative">
          <section className="section section-lg section-shaped pt-100 ">
            <div className="py-lg-md d-flex">
              <div className="col px-0">
                <div>
                  <div lg="6">
                    <Grid
                      container
                      justifyContent="center"
                      spacing={2}
                      direction="column"
                    >
                      <Grid
                        item
                        container
                        justifyContent="center"
                        direction="row"
                      >
                        <Grid item xs={8}>
                          <h1 className="display-1">Galerie</h1>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="center"
                        xs={6}
                        spacing={4}
                        columns={{ xs: 6, sm: 6, md: 12 }}
                      >
                        {items.map((element) => (
                          <Grid item xs={5}>
                            <Carousel>
                              {Object.keys(list)
                                .filter((listelement) =>
                                  listelement.startsWith(element.name)
                                )
                                .map((listelement) => (
                                  <Item
                                    name={element.name}
                                    description={element.description}
                                    image={list[listelement]}
                                  />
                                ))}
                            </Carousel>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Galerie;
